import React, { useState } from "react"
import { PageLayout, PageTitle, BlogLink } from "../components"
import { SEO, Utils } from "../utils"
import { Container, Form, FormControl } from "react-bootstrap"

export default ({ data }) => {
  const [state, setState] = useState({
    filteredData: [],
    query: "",
  })



  return (
    <PageLayout>
      <SEO title="Blog" />
      <PageTitle title="My Blog" />
      <Container className="px-5 mb-5 text-center">
        <Form className="aurebesh blog-filter">
          <FormControl
            className="bg-none search"
            type="text"
            placeholder="Search"
          />
        </Form>
      </Container>
      <Container
        fluid
        className="p-3 w-auto text-left d-flex flex-wrap justify-content-center"
      >
       
      </Container>
    </PageLayout>
  )
}
